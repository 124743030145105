<template>
    <v-container fluid>
        <div>
            <p style="color: green;">Waiting For Process: {{device_signature}}</p>
            <v-simple-table dense class="record_table">
                <tr class="record_heading_tr">
                    <th>Device</th>
                    <th>Last Process AT</th>
                    <th>Last Process Data</th>
                </tr>
                <tr v-for="(device, sl) in $store.getters['device/devices']" :key="sl">
                    <td>{{ device.Name }}</td>
                    <td>{{ device.last_process_at }}</td>
                    <td>{{ device.last_process_data }}</td>
                </tr>
                <tr>
                    <td style="text-align: right;" colspan="2">Total=</td>
                    <td>
                        {{
                            $store.getters['device/devices'].reduce((prev, curr) => {return prev + +curr.last_process_data}, 0)
                        }}
                    </td>
                </tr>
            </v-simple-table>
            
            <v-btn
            color="primary"
            elevation="5"
            dark
            absolute
            top
            x-large
            :style="{left: '50%', top: '40%', transform:'translateX(-50%)', width: '20%', height: '25%'}"
            :loading="loading"
            @click="process"
            >Process</v-btn>
        </div>
    </v-container>
</template>
<script>
import Axios from 'axios';

export default {
    data: () => ({
        loading: false,
        device_signature: 0,
    }),
    created() {
        this.$store.dispatch('device/getDevices');
        this.getWaitingForProcess();
    },
    methods:{
        getWaitingForProcess(){
            axios.get('/get_device_signature').then(res=>{
                this.device_signature = res.data;
            })
        },
        async process(){
            let conf = confirm('Are you sure?');
			if(conf == false){
				return;
			}
            this.loading = true;

            let isSuccess = await this.$store.dispatch('attendance/attendanceProcess');

            if(isSuccess){
                this.loading = false;
                this.$store.dispatch('device/getDevices');
                this.getWaitingForProcess();
            }
        }
    }
}
</script>

<style scoped>
.record_table{
    margin-bottom: 5px;
    width: 30%;
}
.record_table table, .record_table th, .record_table td {
    border: 1px solid #dee2e6;
    border-collapse: collapse;
    padding: 0 !important;
    
}
.record_table th, .record_table td {
    padding: 2px !important;
    font-size: 11px !important;
    text-align: center;
    height: 0 !important;
}

.record_table td {
    color: green;
}

.record_heading_tr{
    background: #4caf7773;
}
</style>